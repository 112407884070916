import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-white py-4 mt-auto">
      <div className="container px-5">
        <div className="row align-items-center justify-content-between flex-column">
          <div className="col-auto">
            <div className="small m-0 text-center">
              Copyright &copy; {currentYear} | All Rights Reserved to Dibya
              Mitra.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
