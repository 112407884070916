import React from "react";

const Experiences = ({ detail }) => {
  return (
    <div className="card shadow border-0 rounded-4 mb-5">
      <div className="card-body p-5">
        <div className="row align-items-center gx-5">
          <div className="col text-center text-lg-start mb-4 mb-lg-0">
            <div className="bg-light p-4 rounded-4">
              <div className="text-primary fw-bolder mb-2">{detail[0]}</div>
              <div className="mb-2">
                <span className="small fw-bolder text-gradient">
                  {detail[1]}
                </span>
                <div className="small text-muted">{detail[2]}</div>
                <div className="small text-muted">({detail[3]})</div>
              </div>
              <div className="fst-italic">
                <div className="small text-muted">{detail[4]}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div>{detail[5]}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experiences;
