import React from "react";
import Skill from "./Skill";

const ProfessionalSkills = ({ skills }) => {
  return (
    <div className="row row-cols-1 row-cols-md-3 mb-4">
      {skills.map((item) => (
        <Skill key={item} skill={item} />
      ))}
    </div>
  );
};

export default ProfessionalSkills;
