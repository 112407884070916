export const ExpertiseArr = [
  ["HTML5", "CSS3", "JavaScript"],
  ["PHP", "Laravel", "CodeIgniter"],
  ["CakePHP", "Symfony", "MySQL"],
  ["jQuery", "React.js", "React Native"],
  ["Python", "Next.js", "Node.js"],
  ["GIT", "Docker"],
];

export const SkillsArr = [
  ["Web Development", "Apps Development"],
  ["Backend Development", "Frontend Development", "Fullstack Development"],
];

export const ExperienceArr = [
  [
    "2024 - Present",
    "Full Stack Developer",
    "Minerva Infotech Private Limited",
    "Hybrid",
    "Kolkata, West Bengal, IN",
    "As a Full Stack Developer, I specialize in backend development with the Symfony Framework and frontend development using React Native and Next.JS. My goal is to guide and support the team in delivering exceptional results. Under the direction of our Project Manager, we complete tasks assigned through GitLab, fulfilling the requirements of our international clients.",
  ],
  [
    "2023 - 2024",
    "Senior Lead Developer",
    "Seekex Technologies Private Limited",
    "Remote",
    "Ludhiana, Punjab, IN",
    "In my role as a Senior Lead Developer, I spearheaded code development in alignment with the directives of our MD. My focus was on enhancing our ERP, utilizing both CakePHP and Laravel frameworks. With a keen eye for detail and a dedication to meeting organizational goals, I ensured that our solutions were efficient, scalable, and tailored to our company's unique needs.",
  ],
  [
    "2021 - 2023",
    "Senior Software Engineer",
    "BCG Attorney Search Private Limited",
    "Remote",
    "Ahmedabad, Gujrat, IN",
    "As a Senior Software Engineer, I've spearheaded code development for our in house law firm project. With precision and expertise, I've crafted efficient solutions tailored to the legal domain's needs, driving success and efficiency within our team.",
  ],
  [
    "2019 - 2021",
    "Senior Developer & Co-Lead",
    "Lee & Nee Software Exports Limited",
    "Onsite",
    "Kolkata, West Bengal, IN",
    "As a Senior Developer and Co-Lead, my focus has centered on refining educational ERPs using CodeIgniter. Leading with innovation, I've optimized systems for streamlined operations, fostering collaboration and efficiency within teams to deliver impactful solutions for educational institutions.",
  ],
  [
    "2016 - 2019",
    "Team Leader",
    "SB3 Software Solutions Private Limited",
    "Onsite",
    "Kolkata, West Bengal, IN",
    "Combining my expertise as a Senior Developer and Team Lead, I orchestrate CodeIgniter projects with precision while nurturing team growth. Engaging directly with clients, I ensure seamless communication and deliver successful outcomes.",
  ],
  [
    "2014 - 2016",
    "Development Team Lead",
    "Sketch Web Solutions",
    "Onsite",
    "Kolkata, West Bengal, IN",
    "Crafting impeccable CodeIgniter code, overseeing the development team, engaging with clients for requirements gathering and updates, and ensuring project triumph and adherence to industry best practices.",
  ],
  [
    "2012 - 2014",
    "Senior Developer",
    "Esolz Technologies Private Limited",
    "Onsite",
    "Kolkata, West Bengal, IN",
    "Developing high-quality code using Core PHP, and CodeIgniter; interacting with clients to gather requirements and provide updates; and helping a team of junior developers to ensure project success and adherence to best practices.",
  ],
];

export const EducationArr = [
  [
    "2010-2013",
    "St. Xaviers College",
    "Kolkata, West Bengal, IN",
    "Master of Computer Applications",
    "Indira Gandhi National Open University",
  ],
  [
    "2009-2010",
    "St. Xaviers College",
    "Kolkata, West Bengal, IN",
    "Post Graduate Diploma in Computer Applications",
    "Indira Gandhi National Open University",
  ],
  [
    "2006-2009",
    "New Alipore College",
    "Kolkata, West Bengal, IN",
    "Bachelor of Science with Mathematics Honors",
    "Calcutta University",
  ],
  [
    "2006",
    "Orient Day School",
    "Kolkata, West Bengal, IN",
    "Indian School Certificate",
    "Council for Indian School Certificate Examinations",
  ],
  [
    "2004",
    "Orient Day School",
    "Kolkata, West Bengal, IN",
    "Indian Certificate for Secondary Education",
    "Council for Indian School Certificate Examinations",
  ],
];
