export const ProjectArr = [
  [
    "Cricket Association of Bengal (CAB)",
    [
      [
        "The Cricket Association of Bengal (CAB) website project, developed in Core PHP, serves as a versatile platform for players, fans, and administrators. Offering features such as membership management, tournament updates, news articles, and event registration, it provides a comprehensive experience tailored to the needs of the cricketing community. With its user-friendly interface and responsive design, the CAB website stands as a central hub for cricket-related information and interaction in Bengal.",
      ],
    ],
    "https://www.cricketassociationofbengal.com",
  ],
  [
    "Nippon Florists",
    [
      [
        "Nippon Florist stands as an exquisite eCommerce platform catering to Japan's discerning clientele. Here, users can seamlessly procure an array of thoughtful gifts, delectable cakes, and exquisite floral arrangements for their loved ones. Integrating the trusted PayPal platform as a seamless payment gateway ensures a secure and hassle-free transaction experience.",
      ],
      [
        "The platform simplifies the gift-giving process, allowing users to place orders with ease, confident that every detail will be diligently attended to. From order processing to timely delivery, our website ensures that each gift reaches its intended recipient promptly and in pristine condition.",
      ],
      [
        "Crafted with precision using CodeIgniter and MySQL, Nippon Florist embodies sophistication and reliability, elevating the art of gift-giving to new heights.",
      ],
    ],
    "https://www.nipponflorist.jp",
  ],
  [
    "B1st Ticketing System",
    [
      [
        "B1st Ticketing System is a premium, yet entirely free, ticketing solution originally developed as a WordPress plugin using CodeIgniter. Now a standalone plugin, it integrates seamlessly with any HTML, PHP, or ASP websites, as well as major CMS platforms such as WordPress, Joomla, Drupal, and PrestaShop.",
      ],
    ],
    "https://github.com/egy1st/b1st-ticketing-system",
  ],
  [
    "Gauhati Law Times Online (GLT Online)",
    [
      [
        "Gauhati Law Times Online is a sophisticated platform tailored to aid legal practitioners in conducting in-depth case analysis, drawing insights from previous citations, and interpreting statutes, regulations, orders, and articles. It boasts an advanced search feature with various criteria, empowering users to efficiently navigate through vast legal databases. Additionally, the website version offers an ecommerce section for purchasing published books by GLT.",
      ],
      ["User categorization includes:"],
      [
        "Premium Users: These individuals have exclusive access to citation search functionalities and can purchase products from the ecommerce section. Premium users subscribe to payment packages, granting them access to confidential search pages for a specified duration.",
      ],
      [
        "Normal Users: Primarily interested in purchasing GLT's published books, normal users can also access search functionalities by selecting payment packages, potentially transitioning into premium users.",
      ],
      ["Search functionalities comprise:"],
      [
        "Word Search: Users can search citations based on Acts, keywords, headnotes, judgments, or all criteria. Suggestions facilitate efficient selection, enhancing search accuracy.",
      ],
      [
        "Court Wise Search: Users can navigate citations based on specific courts and years, facilitating targeted research",
      ],
      [
        "Advanced Search: This comprehensive search page enables users to filter citations based on various criteria, including Acts, rules, regulations, sections, orders, bench type, citation number, court name, judge name, advocate name, publisher, year, volume, and page. Notably, users can refine searches within search results, a pivotal feature enhancing research precision.",
      ],
      [
        "Citation Search: An independent page dedicated to searching citations by unique citation numbers, divided into publisher, year, volume, and page criteria.This sophisticated system is developed using CodeIgniter and MySQL, ensuring seamless functionality and robust performance.",
      ],
    ],
    "https://www.gltonline.co.in",
  ],
  [
    "West Bengal University of Animal and Fishery Sciences (WBUAFS)",
    [
      [
        "Developing the ERP system for the West Bengal University of Animal and Fishery Sciences. This comprehensive system encompasses several critical modules, including:",
      ],
      [
        "Attendance Module: Tracks and manages student and staff attendance efficiently.",
      ],
      [
        "Academic Module: Handles course registrations, grade management, and academic records.",
      ],
      [
        "Timetable Module: Facilitates the creation and management of class schedules.",
      ],
      [
        "Examination Module: Manages exam schedules, grading, and results publication.",
      ],
      [
        "Payroll Module: Streamlines payroll processing and salary management for staff.",
      ],
      [
        "The ERP system was meticulously crafted using CodeIgniter and MariaDB, ensuring robust performance and seamless integration across all modules.",
      ],
    ],
    "https://wbuafscl.ac.in",
  ],
  [
    "Gandhi Institute of Engineering and Technology (GIET)",
    [
      [
        "Developing the ERP system for GIET University (Gandhi Institute of Engineering and Technology). This comprehensive solution includes several essential modules:",
      ],
      [
        "Attendance Module: Efficiently tracks and manages student and staff attendance.",
      ],
      [
        "Academic Module: Oversees course registrations, grade management, and academic records.",
      ],
      [
        "Timetable Module: Enables the creation and administration of class schedules.",
      ],
      [
        "Examination Module: Manages exam schedules, grading processes, and result publications.",
      ],
      [
        "Payroll Module: Streamlines payroll processing and salary management for university staff.",
      ],
      [
        "The ERP system was expertly designed using CodeIgniter and MariaDB, ensuring robust performance and seamless integration across all modules.",
      ],
    ],
    "https://www.giet.edu",
  ],
  [
    "Kingston Educational Institute (KEI)",
    [
      [
        "Developing the ERP system for Kingston Educational Institute, which comprises essential modules as follows:",
      ],
      [
        "Institute Module: This encompasses Kingston Law College, Kingston Polytechnic College, Kingston School of Management and Science, Kingston College of Science, Kingston Teacher's Training College, and Kingston Model School.",
      ],
      [
        "Attendance Module: This module efficiently tracks and manages student and staff attendance.",
      ],
      [
        "Academic Module: It oversees course registrations, grade management, and academic records.",
      ],
      [
        "Timetable Module: Facilitating the creation and administration of class schedules.",
      ],
      [
        "Examination Module: Managing exam schedules, grading processes, and result publications.",
      ],
      [
        "Payroll Module: Streamlining payroll processing and salary management for university staff.",
      ],
      [
        "Crafted with precision using CodeIgniter and MariaDB, the ERP system ensures robust performance and seamless integration across all modules.",
      ],
    ],
    "https://keical.edu.in",
  ],
  [
    "Hiscraves Fashion Blog",
    [
      [
        "Hiscrave stands as a premier T-shirt Manufacturing Company, boasting its own eCommerce Portal under the name Hiscraves. Leveraging cutting-edge technologies such as CakePHP, Laravel, and MySQL, I meticulously developed the following modules:",
      ],
      [
        "Panel Module: This foundational module orchestrates the intricate process of T-shirt creation. From initiating manufacturing orders to batching and cutting cloth into bundles, each piece, or panel, undergoes meticulous stitching to form the final product. Advanced functionalities include managing panel inventory and coordinating with external parties for job work.",
      ],
      [
        "Mixture Module: A critical component in mass printing, this module ensures the precise formulation of printing mixtures. By meticulously controlling solvent proportions, it guarantees print durability and minimizes wastage through intelligent mixture utilization.",
      ],
      [
        "Photo Collage Module: Simplifying catalog creation, this module seamlessly collates uploaded photos into visually appealing collages, enhancing product presentation and marketing effectiveness.",
      ],
      [
        "Survey Module: Leveraging Pinterest boards, this module facilitates market surveys through a React App. Users can engage with surveys, providing valuable feedback and ratings, enhancing market insight and strategy formulation.",
      ],
      [
        "Telegram Module: Automating competitor analysis, this Python-powered module monitors competitor Telegram groups, modifies affiliate URLs, and disseminates strategic messages through the company's channels, streamlining marketing efforts.",
      ],
      [
        "Screen Module: Essential for printing, this module facilitates screen creation and maintenance, optimizing printing processes and ensuring print quality consistency.",
      ],
      [
        "Printing Cost Calculation Module: By meticulously analyzing material, electrical, and labor costs, this module provides accurate printing cost estimates. It further evaluates machine efficiency and employee productivity based on daily production metrics, fostering cost optimization and operational efficiency.",
      ],
      [
        "Product Class Modules: This dynamic module intelligently assigns product classes (A, B, C, D, E) based on sales performance, leveraging sophisticated algorithms to optimize inventory management and sales strategy.",
      ],
      [
        "Gate Pass Module: Streamlining employee attendance tracking, this module manages employee movements within and between company units, ensuring compliance and operational efficiency.",
      ],
      [
        "These meticulously crafted modules embody Hiscrave's commitment to excellence and innovation in the realm of T-shirt manufacturing.",
      ],
    ],
    "https://www.hiscraves.com",
  ],
  [
    "Menrocks Survey",
    [
      [
        "I've also crafted the React App alongside corresponding APIs to complement the sophisticated Survey Module outlined above for Hiscraves Fashion Blog. This seamless integration enhances user engagement and empowers efficient data collection, underscoring our commitment to excellence in every facet of our offerings.",
      ],
    ],
    "https://menrocks-survey.sevenrocks.in",
  ],
  [
    "The Library",
    [
      [
        'Additionally, I\'ve helmed another project crafted with precision using Laravel and MySQL, showcasing an inventive eCommerce platform dedicated to hosting songs and tunes. Embracing seamless user interaction, visitors can effortlessly purchase songs online, with download links promptly provided post-purchase. Notably tailored for a Chinese audience, the platform seamlessly integrates the renowned "WeChat Pay" as its preferred payment gateway. While the current website link is unavailable, rest assured, it embodies sophistication and user-centric design principles, elevating the digital music experience for all.',
      ],
    ],
    "",
  ],
];
