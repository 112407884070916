import React, { useState } from "react";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";
import axios from "axios"; // Import axios for making HTTP requests

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [formStatus, setFormStatus] = useState(""); // Add state to manage form submission status

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const validate = () => {
    let tempErrors = {};

    if (!formData.name) tempErrors.name = "A name is required.";
    if (!formData.email) tempErrors.email = "An email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      tempErrors.email = "Email is not valid.";
    if (!formData.phone) tempErrors.phone = "A phone number is required.";
    if (!formData.subject) tempErrors.subject = "A subject is required.";
    if (!formData.message) tempErrors.message = "A message is required.";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        await axios.post("https://dibyamitra.me/api/", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        setFormStatus("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      } catch (error) {
        console.error("Submission Error:", error.response || error.message);
        setFormStatus("Failed to send the message. Please try again.");
      }
    }
  };

  return (
    <React.Fragment>
      <main className="flex-shrink-0">
        <Navbar />
        <section className="py-5">
          <div className="container px-5">
            <div className="bg-light rounded-4 py-5 px-4 px-md-5">
              <div className="text-center mb-5">
                <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 mb-3">
                  <i className="bi bi-envelope"></i>
                </div>
                <h1 className="fw-bolder">Get in touch</h1>
                <p className="lead fw-normal text-muted mb-0">
                  Let's work together!
                </p>
              </div>
              <div className="row gx-5 justify-content-center">
                <div className="col-lg-8 col-xl-6">
                  <form id="contactForm" onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                      <input
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        id="name"
                        type="text"
                        placeholder="Enter your name..."
                        value={formData.name}
                        onChange={handleChange}
                      />
                      <label htmlFor="name">Full name</label>
                      {errors.name && (
                        <div className="invalid-feedback d-block">
                          {errors.name}
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        id="email"
                        type="email"
                        placeholder="name@example.com"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <label htmlFor="email">Email address</label>
                      {errors.email && (
                        <div className="invalid-feedback d-block">
                          {errors.email}
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className={`form-control ${
                          errors.phone ? "is-invalid" : ""
                        }`}
                        id="phone"
                        type="tel"
                        placeholder="(123) 456-7890"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      <label htmlFor="phone">Phone number</label>
                      {errors.phone && (
                        <div className="invalid-feedback d-block">
                          {errors.phone}
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className={`form-control ${
                          errors.subject ? "is-invalid" : ""
                        }`}
                        id="subject"
                        type="text"
                        placeholder="Enter your subject..."
                        value={formData.subject}
                        onChange={handleChange}
                      />
                      <label htmlFor="subject">Subject</label>
                      {errors.subject && (
                        <div className="invalid-feedback d-block">
                          {errors.subject}
                        </div>
                      )}
                    </div>
                    <div className="form-floating mb-3">
                      <textarea
                        className={`form-control ${
                          errors.message ? "is-invalid" : ""
                        }`}
                        id="message"
                        placeholder="Enter your message here..."
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      <label htmlFor="message">Message</label>
                      {errors.message && (
                        <div className="invalid-feedback d-block">
                          {errors.message}
                        </div>
                      )}
                    </div>
                    <div className="d-grid">
                      <button className="btn btn-gold btn-lg" type="submit">
                        Submit
                      </button>
                    </div>
                    {formStatus && (
                      <div
                        className={`mt-3 ${
                          formStatus.includes("success")
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {formStatus}
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Contact;
