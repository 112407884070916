import React from "react";
import ProjectDescriptionPara from "./ProjectDescriptionPara";

const ProjectList = ({ detail }) => {
  return (
    <div className="card overflow-hidden shadow rounded-4 border-0 mb-5">
      <div className="card-body p-0">
        <div className="d-flex align-items-center">
          <div className="p-5">
            <h4 className="fw-bolder mb-3">{detail[0]}</h4>
            {detail[1].map((item) => (
              <ProjectDescriptionPara key={item} para={item} />
            ))}
            <a className="portfolio-link" href={detail[2]}>
              {detail[2]}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
