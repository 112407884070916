import React from "react";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";
import Expertise from "./Expertise";
import Experiences from "./Experiences";
import Education from "./Education";
import ProfessionalSkills from "./ProfessionalSkills";
import {
  ExpertiseArr,
  SkillsArr,
  ExperienceArr,
  EducationArr,
} from "./data/ResumeData";

const baseUrl = window.location.origin;
const ResumeLink = baseUrl + "/assets/Resume.pdf";

const Resume = () => {
  return (
    <React.Fragment>
      <main className="flex-shrink-0">
        <Navbar />
        <div className="container px-5 my-5">
          <div className="text-center mb-5">
            <h1 className="display-5 fw-bolder mb-0">
              <span className="text-gradient d-inline">Resume</span>
            </h1>
          </div>
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-11 col-xl-9 col-xxl-8">
              <section>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h2 className="text-primary fw-bolder mb-0">Experience</h2>
                  <a
                    download
                    className="btn btn-gold px-4 py-3"
                    href={ResumeLink}
                  >
                    <div className="d-inline-block bi bi-download me-2"></div>
                    Download Resume
                  </a>
                </div>
                {ExperienceArr.map((item) => (
                  <Experiences key={item} detail={item} />
                ))}
              </section>
              <section>
                <h2 className="text-primary fw-bolder mb-4">Education</h2>
                {EducationArr.map((item) => (
                  <Education key={item} detail={item} />
                ))}
              </section>
              <div className="pb-5"></div>
              <section>
                <div className="card shadow border-0 rounded-4 mb-5">
                  <div className="card-body p-5">
                    <div className="mb-5">
                      <div className="d-flex align-items-center mb-4">
                        <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                          <i className="bi bi-tools"></i>
                        </div>
                        <h3 className="fw-bolder mb-0">
                          <span className="text-gradient d-inline">
                            Professional Skills
                          </span>
                        </h3>
                      </div>
                      {SkillsArr.map((item) => (
                        <ProfessionalSkills key={item} skills={item} />
                      ))}
                    </div>
                    <div className="mb-0">
                      <div className="d-flex align-items-center mb-4">
                        <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                          <i className="bi bi-code-slash"></i>
                        </div>
                        <h3 className="fw-bolder mb-0">
                          <span className="text-gradient d-inline">
                            Expertise
                          </span>
                        </h3>
                      </div>
                      {ExpertiseArr.map((item) => (
                        <Expertise key={item} technologies={item} />
                      ))}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Resume;
