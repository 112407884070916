import React from "react";
import HomeHeader from "./HomeHeader";
import About from "./About";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";

const Home = () => {
  return (
    <React.Fragment>
      <main className="flex-shrink-0">
        <Navbar />
        <HomeHeader />
      </main>
      <About />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
