import React from "react";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";
import ProjectList from "./ProjectList";
import { Link } from "react-router-dom";
import { ProjectArr } from "./data/ProjectData";

const Projects = () => {
  return (
    <React.Fragment>
      <main className="flex-shrink-0">
        <Navbar />
        <section className="py-5">
          <div className="container px-5 mb-5">
            <div className="text-center mb-5">
              <h1 className="display-5 fw-bolder mb-0">
                <span className="text-gradient d-inline">Projects</span>
              </h1>
            </div>
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-11 col-xl-9 col-xxl-8">
                {ProjectArr.map((item) => (
                  <ProjectList key={item} detail={item} />
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 bg-gradient-primary-to-secondary text-white">
          <div className="container px-5 my-5">
            <div className="text-center">
              <h2 className="display-4 fw-bolder mb-4">
                Let's build something together
              </h2>
              <Link
                className="btn btn-outline-light btn-lg px-5 py-3 fs-6 fw-bolder"
                to="/contact"
              >
                Contact me
              </Link>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Projects;
