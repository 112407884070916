import React from "react";
import Dots from "./Dots";
import profileLogo from "./assets/profile.png";
import { Link } from "react-router-dom";

const dotArr = [1, 2, 3, 4];

const HomeHeader = () => {
  return (
    <header className="py-5">
      <div className="container px-5 pb-5">
        <div className="row gx-5 align-items-center">
          <div className="col-xxl-5">
            <div className="text-center text-xxl-start">
              <div className="badge bg-gradient-primary-to-secondary text-white mb-4">
                <div className="text-uppercase">12 years of expertise</div>
              </div>
              <div className="fs-3 fw-light text-muted">
                I bulid scalable Laravel, Symfony and React solutions
              </div>
              <h1 className="display-3 fw-bolder mb-5">
                <span className="text-gradient d-inline">
                  That drive business growth
                </span>
              </h1>
              <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3">
                <Link
                  className="btn btn-navy btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder"
                  to="/resume"
                >
                  Resume
                </Link>
                <Link
                  className="btn btn-outline-gold btn-lg px-5 py-3 fs-6 fw-bolder"
                  to="/projects"
                >
                  Projects
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xxl-7">
            <div className="d-flex justify-content-center mt-5 mt-xxl-0">
              <div className="profile bg-gradient-primary-to-secondary">
                <img
                  className="profile-img"
                  src={profileLogo}
                  alt="Dibya Mitra"
                />
                {dotArr.map((item) => (
                  <Dots key={item} num={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
