import React from "react";
import Language from "./Language";

const Expertise = ({ technologies }) => {
  return (
    <div className="row row-cols-1 row-cols-md-3 mb-4">
      {technologies.map((item) => (
        <Language key={item} language={item} />
      ))}
    </div>
  );
};

export default Expertise;
