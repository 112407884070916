import React from "react";

const About = () => {
  return (
    <section className="bg-light py-5">
      <div className="container px-5">
        <div className="row gx-5 justify-content-center">
          <div className="col-xxl-8">
            <div className="text-center my-5">
              <h2 className="display-5 fw-bolder">
                <span className="text-gradient d-inline">About Me</span>
              </h2>
              <p className="lead fw-light mb-4">My name is Dibya Mitra</p>
              <p className="text-muted">
                I streamline complex PHP Framework projects like CodeIgniter,
                CakePHP, Laravel, and Symfony to ensure efficient data handling,
                and enhance web solutions with React and Python expertise.
              </p>
              <div className="d-flex justify-content-center fs-2 gap-4">
                <a
                  className="text-gradient"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/dibya-mitra/"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
                <a
                  className="text-gradient"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/dibyamitr"
                >
                  <i className="bi bi-github"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
